
            @import "@/assets/sass/vars.portal.scss";
          













































































































































































































































































































































.entity-has-error {
    border: 2px solid $danger !important;
}
