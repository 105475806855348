
            @import "@/assets/sass/vars.portal.scss";
          
































.s-vars {
    li {
        margin-bottom: 10px;
    }
}
